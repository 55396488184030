import testimonio1 from "../../img/landing_hotel/testimonio1.png";
import testimonio2 from "../../img/landing_hotel/testimonio2.png";
import testimonio3 from "../../img/landing_hotel/testimonio3.png";

const Testimonios = () => {
    const testimonios = [
        {
            nombre: "Juan Pérez",
            cargo: "Hotel en Arequipa",
            texto: "Desde que implementamos BluStay, la gestión de reservas se ha simplificado enormemente, y nuestros huéspedes aprecian la comunicación directa a través del chat.",
        },
        {
            nombre: "María Gómez",
            cargo: "Hotel en Paracas",
            texto: "Las herramientas de promoción de BluStay nos han permitido aumentar la ocupación durante la temporada baja con ofertas personalizadas.",
        },
        {
            nombre: "Carlos Ramirez",
            cargo: "Hotel en Cusco",
            texto: "Gracias a BluStay, hemos optimizado la gestión de nuestras habitaciones y la comunicación con los huéspedes. La integración del calendario de reservas y el CRM nos ha permitido ofrecer un servicio más personalizado y eficiente.",
        },
    ];

    return (
        <div className="md723:px-12 px-5 py-6 mx-auto ">
            <div className="grid grid-cols-1 gap-8 md877:grid-cols-3 bg-[#029DE01A]/10 md723:p-10 p-4 rounded-xl">
                {testimonios.map((testimonio, index) => (
                    <div
                        key={index}
                        className="flex flex-col h-full rounded-xl"
                    >
                        <div className="bg-[#C3EA43] rounded-t-xl p-4 flex items-center gap-2">
                            <div>
                                <img
                                    src={
                                        index === 0
                                            ? testimonio1
                                            : index === 1
                                            ? testimonio2
                                            : testimonio3
                                    }
                                    alt="testimonio"
                                    className="w-12 h-12 mx-auto"
                                />
                            </div>
                            <div>
                                <h3 className="text-lg font-bold text-[#071829]">
                                    {testimonio.nombre}
                                </h3>
                                <p className=" text-sm md877:text-xs lg:text-sm font-bold text-[#029DE0]">
                                    {testimonio.cargo}
                                </p>
                            </div>
                        </div>
                        <p className="text-[#071829] text-sm md877:text-xs lg:text-sm p-4 lg:p-6 bg-white rounded-b-xl shadow-lg flex-1 flex items-center">
                            <span className="leading-none text-gray-400">
                                "
                            </span>
                            {testimonio.texto}
                            <span className="leading-none text-gray-400">
                                "
                            </span>
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Testimonios;
