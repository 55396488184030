import React, { useEffect, useRef, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";

import axios from "axios";

import Swal from "sweetalert2";
import { useNavigate } from "react-router";

import logo from "../../img/logoPNGalerta.png";

import { useParams } from "react-router";
export const FormularioHotel = () => {
    registerLocale("es", es);

    const navigate = useNavigate();
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };

    const { fechas } = useParams();

    const [enabledPersonas, setEnabledPersonas] = useState(false);
    const [openCalendar, setOpenCalendar] = useState(false);
    const [cargando, setCargando] = useState(false);

    const [input, setInput] = useState({
        nombre: "",

        correo: "",

        celular: "",
        hotel: "",
    });

    const enviar = async () => {
        if (
            input.nombre === "" ||
            input.correo === "" ||
            input.celular === "" ||
            input.hotel === ""
        ) {
            console.log(input);
            Swal.fire({
                icon: "error",
                title: "Todos los campos son requeridos",
                showConfirmButton: false,
                timer: 1500,
            });
            return;
        }
        setCargando(true);

        const { data } = await axios.post(
            `${process.env.REACT_APP_PROXY}/casa/solicitarPrueba`,
            {
                nombre: `${input.nombre}`,
                correo: input.correo,
                celular: input.celular,
                hotel: input.hotel,
            },
            { headers }
        );
        setCargando(false);

        const mensaje = `
Hola, vengo de la landing de hoteles, quiero solicitar una prueba

*Mis datos son*:
*Nombre*: ${input.nombre}
*Celular*: ${input.celular}
*Correo*: ${input.correo} 
*Nombre del hotel*: ${input.hotel}`;

        Swal.fire({
            confirmButtonColor: "#029BFC",
            iconColor: "#029BFC",
            title: "Mensaje enviado!",
            imageUrl: `${logo}`,
            imageWidth: 300,
            imageHeight: 300,
            text: "En breve nos pondremos en contacto",
            // icon: "success",
            confirmButtonText: "Aceptar",
        });

        const mensajeCodificado = encodeURIComponent(mensaje);

        // const numeroWhatsApp = "554598373045";
        const numeroWhatsApp = "51950707924";

        const urlWhatsApp = `https://wa.me/${numeroWhatsApp}?text=${mensajeCodificado}`;
        window.open(urlWhatsApp, "_blank");
        return;
    };

    return (
        <div className="">
            <div className="">
                <div className=" py-10 bg-white rounded-lg select-none">
                    <p className=" 2xl:text-4xl w-10/12 mx-auto xl:text-2xl text-2xl font-bold text-center text-[#029DE0]">
                        ¡Regístrate y mejora tu gestión!
                    </p>
                    <div className="relative grid w-10/12 gap-4 mx-auto mt-10 2xl:mt-14 ">
                        <div className="flex flex-col ">
                            <label className="mb-1 text-sm tracking-wide text-left text-[#053465] font-bold">
                                Nombre completo
                            </label>
                            <input
                                autoComplete="off"
                                type="text"
                                name="nombre"
                                onChange={(e) =>
                                    setInput({
                                        ...input,
                                        nombre: e.target.value,
                                    })
                                }
                                value={input.nombre}
                                className="w-full 2xl:py-3 py-2 pl-4 pr-4 text-sm border border-[#e5e7eb] rounded-lg  disabled:bg-slate-50  placeholder:text-[#053465] text-[#053465] bg-[#ECECEC]"
                            />
                        </div>
                    </div>

                    <div className="relative grid w-10/12 gap-4 mx-auto mt-5 ">
                        <div className="flex flex-col ">
                            <label className="mb-1 text-sm tracking-wide text-left text-[#053465] font-bold">
                                Correo
                            </label>
                            <input
                                autoComplete="off"
                                type="text"
                                name="correo"
                                onChange={(e) =>
                                    setInput({
                                        ...input,
                                        correo: e.target.value,
                                    })
                                }
                                value={input.correo}
                                className="w-full 2xl:py-3 py-2 pl-4 pr-4 text-sm border border-[#e5e7eb] rounded-lg  disabled:bg-slate-50  placeholder:text-[#053465] text-[#053465] bg-[#ECECEC]"
                            />
                        </div>
                    </div>

                    <div className="relative grid w-10/12 gap-4 mx-auto mt-5 ">
                        <div className="flex flex-col ">
                            <label className="mb-1 text-sm tracking-wide text-left text-[#053465] font-bold">
                                Nombre del hotel
                            </label>
                            <input
                                autoComplete="off"
                                type="text"
                                name="hotel"
                                onChange={(e) =>
                                    setInput({
                                        ...input,
                                        hotel: e.target.value,
                                    })
                                }
                                value={input.hotel}
                                className="w-full 2xl:py-3 py-2 pl-4 pr-4 text-sm border border-[#e5e7eb] rounded-lg  disabled:bg-slate-50  placeholder:text-[#053465] text-[#053465] bg-[#ECECEC]"
                            />
                        </div>
                    </div>

                    <div className="relative grid w-10/12 gap-4 mx-auto mt-5 ">
                        <div className="flex flex-col ">
                            <label className="mb-1 text-sm tracking-wide text-left text-[#053465] font-bold">
                                Celular
                            </label>
                            <input
                                autoComplete="off"
                                type="number"
                                name="celular"
                                onChange={(e) =>
                                    setInput({
                                        ...input,
                                        celular: e.target.value,
                                    })
                                }
                                value={input.celular}
                                className="w-full 2xl:py-3 py-2 pl-4 pr-4 text-sm border border-[#e5e7eb] rounded-lg  disabled:bg-slate-50  placeholder:text-[#053465] text-[#053465] bg-[#ECECEC]"
                            />
                        </div>
                    </div>

                    <div className=" flex justify-center items-center w-full flex-col gap-4 mx-auto mt-10">
                        <button
                            disabled={cargando}
                            onClick={() => enviar()}
                            className={`mx-2 text-center w-11/12`}
                        >
                            <p
                                className={`font-semibold bg-blue-500 rounded-lg min-w-max p-2 py-2 2xl:py-2.5 text-white mx-auto w-11/12 disabled:bg-blue-300 ${
                                    !cargando ? "animate-pulseSlow" : ""
                                }`}
                            >
                                {cargando
                                    ? "Enviando..."
                                    : "¡Solicita tu prueba gratuita!"}
                            </p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
