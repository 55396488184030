import { useState } from "react";
import Caracteristicas from "./Caracteristicas";

import Beneficios from "./Beneficios";
import Testimonios from "./Testimonios";

import { Banner } from "./Banner";

import DemoBanner from "./DemoBanner";

import Planes from "./Planes";
import { Footer } from "./Footer";

const LandingHotelHome = () => {
    const [selectedDesign, setSelectedDesign] = useState(3);

    return (
        <div>
            <div className="">
                <div className="">
                    <Banner />
                </div>
                <div className="mx-auto 3xl:w-5/6 xl:w-11/12">
                    <Caracteristicas />
                    <Beneficios />
                    <Testimonios />

                    <Planes />
                    <DemoBanner />
                    <Footer messageWhatsapp="hotel" />
                </div>
            </div>
        </div>
    );
};

export default LandingHotelHome;
